import React from "react"

import styles from '@styles/pages/404.module.scss'
import Layout from "@comp/layout"
import SEO from '@comp/seo'
import PageTitle from '@comp/page_title'

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} pageName="404">
      <SEO
        title={`404 NOT FOUND`}
        description={`404 NOT FOUND`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <h1 className={styles.title}>404 NOT FOUND</h1>
          <p className={styles.txt}>ご指定のURLが見つかりませんでした。</p>
        </div>
      </main>
    </Layout>
  )
}

export default NotFoundPage
